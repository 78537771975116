import React from 'react';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import BackgroundSlider from 'gatsby-image-background-slider';

const BckgrdSlider = ({ children }) => (
  <>
    <main>{children}</main>
    <BackgroundSlider
      query={useStaticQuery(graphql`
        query {
          backgrounds: allFile(filter: { sourceInstanceName: { eq: "backgrounds" } }) {
            nodes {
              relativePath
              childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      `)}
      initDelay={2} // delay before the first transition (if left at 0, the first image will be skipped initially)
      transition={1} // transition duration between images
      duration={10} // how long an image is shown
      // specify images to include (and their order) according to `relativePath`
      images={['hero.jpg', 'maszyna.jpg', 'bus.jpg']}
      // pass down standard element props
      style={{
        
      }} 
    >
    </BackgroundSlider>
  </>
);

const StyledBackgroundSection = styled(BckgrdSlider)`
  width: 100%;
  background-position: bottom center;
  background-repeat: repeat-y;
  background-size: cover;
`;

export default StyledBackgroundSection;