import React, { useContext, useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import { Container } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import Bounce from 'react-reveal/Bounce';
import PortfolioContext from '../../context/context';
import StyledBackgroundSection from '../BckgrdSlider';


const Header = () => {
  const { hero } = useContext(PortfolioContext);
  const { title, name, subtitle, cta } = hero;

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  return (
    <StyledBackgroundSection>
    <section id="hero" className="jumbotron">
      <Container>
        <Fade left={isDesktop} bottom={isMobile} duration={500} delay={500} distance="30px">
          <h1 className="hero-title">
            {title || 'BRAND SODA'} <span style={{color: "white"}}>{name || 'SYSTEM'}</span>
          </h1>
          <h2 className="hero-sub">kompleksowe czyszczenie i malowanie</h2>
        </Fade>
        <Bounce bottom>
          <h2 className="hero-title">tel. 533-917-983</h2>
        </Bounce>
        <Fade left={isDesktop} bottom={isMobile} duration={500} delay={500} distance="30px">
          <p className="hero-cta">
            <a className="cta-btn cta-btn--resume" href="#about">
              {cta || 'Więcej'}
            </a>
          </p>
        </Fade>
      </Container>
    </section>
  </StyledBackgroundSection>
  );
};

export default Header;
