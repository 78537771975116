import React, { useContext, useState, useEffect } from 'react';
import Fade from 'react-reveal/Fade';
import { Container, Row, Col } from 'react-bootstrap';
import Title from '../Title/Title';
import AboutImg from '../Image/AboutImg';
import { Link } from "gatsby";
import PortfolioContext from '../../context/context';

const About = () => {
  const { about } = useContext(PortfolioContext);
  const { img, img2, img3, paragraphOne, paragraphTwo, paragraphThree, resume } = about;

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  return (
    <section id="cien_about">
      <section id="about" data-spy="scroll" data-target="#nawigacja">
        <br />
        <br />
        <Container>
          <Title title="O nas" />
          <Row className="about-wrapper">
            <Col md={6} sm={12}>
              <Fade bottom duration={1000} delay={600} distance="30px">
                <div className="about-wrapper__image">
                  <AboutImg alt="profile picture" filename={img3} />
                </div>
              </Fade>
            </Col>
            <Col md={6} sm={12}>
              <Fade left={isDesktop} bottom={isMobile} duration={500} delay={500} distance="30px">
                <div className="about-wrapper__info">
                  <p className="about-wrapper__info-text">
                    {paragraphOne ||
                      'Od 2017 roku działamy na rynku i daliśmy się poznać jako sprawdzona i solidna firma. Jesteśmy młodym, energicznym, ciągle rozwijającym się zespołem. Postanowiliśmy pójść w swojej pracy o krok dalej i postawić na ekologię. Skupiliśmy się na nowoczesnych i innowacyjnych rozwiązaniach, które są całkowicie nieszkodliwe nie tylko dla pracownika ale również dla środowiska. Do pracy wykorzystujemy oryginalne maszyny Soda Blast Systems. Dysponujemy mobilnym urządzeniem, dlatego nasze usługi możemy wykonywać u klienta.'}
                  </p>
                </div>
              </Fade>
            </Col>
          </Row>
          <Row className="about-wrapper">
            <Col md={6} sm={12}>
              <Fade left={isDesktop} bottom={isMobile} duration={500} delay={500} distance="30px">
                <div className="about-wrapper__info">
                  <p className="about-wrapper__info-text">
                    {paragraphTwo ||
                      'Oferujemy profesjonalne czyszczenie wszelkiego rodzaju powierzchni takich jak elewacje budynków (ceglanych, drewnianych, otynkowanych z wieloletnich zabrudzeń, farb, zbitych tynków, impregnatów, osmoleń po pożarze) ale również karoserie pojazdów, łodzie, konstrukcje stalowe oraz wiele innych. Używamy metody strumieniowo-ściernej do której zalicza się sodowanie, piaskowanie, szkiełkowanie.'}
                  </p>
                </div>
              </Fade>
            </Col>
            <Col md={6} sm={12}>
              <Fade bottom duration={500} delay={300} distance="30px">
                <div className="about-wrapper__image">
                  <AboutImg alt="profile picture" filename={img} />
                </div>
              </Fade>
            </Col>
          </Row>
          <Row className="about-wrapper">
            <Col md={6} sm={12}>
              <Fade bottom duration={500} delay={300} distance="30px">
                <div className="about-wrapper__image">
                  <AboutImg alt="profile picture" filename={img2} />
                </div>
              </Fade>
            </Col>
            <Col md={6} sm={12}>
              <Fade left={isDesktop} bottom={isMobile} duration={500} delay={500} distance="30px">
                <div className="about-wrapper__info">
                  <p className="about-wrapper__info-text">
                    {paragraphThree ||
                      'Zlecenia realizujemy dokładnie, czas oczekiwania na usługę skracamy do minimum. Posiadamy uprawnienia do pracy na wysokościach. Cechuje nas pasja z jaką wydobywamy nowe życie ze starych przedmiotów i rzeczy.'}
                  </p>
                  <p className="about-wrapper__info-text">
                    Serdecznie zapraszamy do zapoznania się z naszą ofertą.
                  </p>
                  <Link style={{color:'white'}} to="/oferta">
                    <span className="d-flex mt-3">
                      <a
                        className="cta-btn cta-btn--resume"
                      >
                        Oferta
                      </a>
                    </span>
                  </Link>
                </div>
              </Fade>
            </Col>
          </Row>
        </Container>
      </section>
    </section>
  );
};

export default About;
