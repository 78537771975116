import React, { useContext } from 'react';
import Fade from 'react-reveal/Fade';
import { Container } from 'react-bootstrap';
import PortfolioContext from '../../context/context';
import Title from '../Title/Title';

const Contact = () => {
  const { contact } = useContext(PortfolioContext);
  const { cta, btn, email } = contact;

  return (
    <section id="cien">
      <section id="contact">
        <Container>
          <Title title="Kontakt" />
          <Fade bottom duration={1000} delay={800} distance="30px">
            <div className="contact-wrapper">
              <p className="contact-wrapper__text">{cta || 'Napisz lub zadzwońdo nas!'}</p>
              <p className="contact-wrapper__text">tel. 533 917 983</p>
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="cta-btn cta-btn--resume"
                href={email ? `mailto:${email}` : 'https://github.com/cobidev/react-simplefolio'}
              >
                {btn || 'Napisz teraz!'}
              </a>
            </div>
          </Fade>
        </Container>
      </section>
    </section>
  );
};

export default Contact;
